import { objectEntries } from '~/lib/object-entries'

import {
  FiltersAndSortPanel,
  FiltersPanelProps,
} from '~/components/UI/Filters/Panels'
import { Facet } from '~/components/UI/Filters/Panels/Filtering/Facet'

import { useSearchAndDiscoveryContext } from '~/providers/SearchAndDiscoveryProvider'

export function FiltersPanel({ closePanel }: FiltersPanelProps) {
  const { facets, filters, setFilters, setPage } =
    useSearchAndDiscoveryContext()

  return (
    <FiltersAndSortPanel
      defaultValues={filters}
      facetComponent={Facet}
      facets={
        facets?.map((facet, index) => ({
          name: facet?.processedName,
          index,
        })) ?? []
      }
      onReset={() => {
        setFilters((prevState) => {
          return Object.keys(prevState)?.reduce((acc, key) => {
            return {
              ...acc,
              [key]: null,
            }
          }, {})
        })
        setPage(1)
      }}
      onSubmit={(data) => {
        const selectedFilters = objectEntries(data)?.reduce(
          (acc, [key, values]) => {
            if (!values) return acc

            if (!Array.isArray(values)) {
              return {
                ...acc,
                [key]: [values]?.filter(Boolean),
              }
            }

            return {
              ...acc,
              [key]: values?.filter(Boolean) ?? [],
            }
          },
          {},
        )

        setFilters(selectedFilters)
        setPage(1)
        closePanel()
      }}
    />
  )
}
