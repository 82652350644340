import classnames from 'classnames/bind'
import type { TPrismicTypesKey } from '~/lib/prismic-types'
import { PRISMIC_TYPES, isPageWithoutMarginTop } from '~/lib/prismic-types'

import Meta from '~/components/Abstracts/Meta'
import PanelManager from '~/components/Abstracts/Panels/PanelManager'
import PopInManager from '~/components/Abstracts/PopInManager'
import { VercelSpeedInsights } from '~/components/Abstracts/VercelSpeedInsights'
import Navigation from '~/components/Navigation/Navigation'
import VenusNavigation from '~/components/Navigation/VenusNavigation'
import { usePopinContests } from '~/components/Popins/PopinContests/hook'
import { CustomAlerts } from '~/components/UI/Alerts'
import Footer from '~/components/UI/Footer'
import PopInNewsletterHandler from '~/components/UI/PopInNewsletterHandler'

import useSetGiftProducts from '~/hooks/cart/useSetGiftProducts'
import useGeopositionRedirect from '~/hooks/useGeopositionRedirect'

import withPrivate from '~/hocs/withPrivate'

import { DefaultPageData } from '~/data/page-data/serializer'

import SLICES_WITHOUT_MARGIN_TOP from './slices-without-margin-top'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PageProps extends DefaultPageData {
  children?: JSX.Element | JSX.Element[]
}
function Page({
  children,
  metas,
  global,
  slices,
  document,
  navigationTheme,
  navigationDisabled,
  popInNewsletterDisabled,
}: PageProps) {
  const withoutMarginTop =
    SLICES_WITHOUT_MARGIN_TOP.indexOf(slices?.[0]?.type) > -1
  const pageHasNoMarginTop =
    isPageWithoutMarginTop(document?.type as TPrismicTypesKey) ?? false

  const fragrancePouchs =
    global?.gifts?.types?.find(
      (item) => item?.type === PRISMIC_TYPES.PRODUCT_FRAGRANCE,
    )?.pouchs ?? null

  useGeopositionRedirect()
  useSetGiftProducts({ gifts: fragrancePouchs })
  usePopinContests()

  const isVenusPage = document?.type === PRISMIC_TYPES.VENUS

  const isHeroTitleOnly = Array.isArray(children)
    ? children[0]?.props?.isHeroTitleOnly
    : children?.props?.isHeroTitleOnly

  return (
    <>
      <Meta {...metas} isRootPage={document?.type === PRISMIC_TYPES.HOMEPAGE} />
      {!global?.newsletter?.disabled && !popInNewsletterDisabled && (
        <PopInNewsletterHandler />
      )}

      {isVenusPage ? (
        <VenusNavigation
          banner={global?.banner}
          venusNavigationItems={global?.venusNavigation}
          {...global?.navigation}
          isDisabled={navigationDisabled}
        />
      ) : (
        <Navigation
          banner={global?.banner}
          theme={navigationTheme}
          {...global?.navigation}
          isDisabled={navigationDisabled}
        />
      )}
      <main
        className={cx(css.Page, {
          [css.venusPage]: isVenusPage,
          withoutMarginTop,
          pageHasNoMarginTop,
          isHeroTitleOnly,
        })}>
        {children}
      </main>

      <Footer
        {...global?.footer}
        pageType={document?.type ?? null}
        isVenusPage={isVenusPage}
        theme={isVenusPage ? 'light' : 'dark'}
      />

      <PanelManager />
      <PopInManager />
      <CustomAlerts />
      <VercelSpeedInsights />
    </>
  )
}

Page.defaultProps = {}

export default withPrivate(Page)
