import { SHOPIFY_TYPES } from '~/lib/shopify/constants'

type ProductTypeProps = string

/**
 * The function `getIsProductFashion` checks if the given `ProductTypeProps` is equal to
 * `SHOPIFY_TYPES.PRODUCT_FASHION`.
 * @param {ProductTypeProps} type - The `type` parameter in the `getIsProductFashion` function is of
 * type `ProductTypeProps`, which is used to determine if a product is of fashion type. The function
 * checks if the `type` is equal to `SHOPIFY_TYPES.PRODUCT_FASHION` and returns
 * @returns The function `getIsProductFashion` returns a boolean value indicating whether the `type`
 * parameter is equal to `SHOPIFY_TYPES.PRODUCT_FASHION`.
 */
export function getIsProductFashion(type: ProductTypeProps) {
  return type === SHOPIFY_TYPES.PRODUCT_FASHION
}

/**
 * The function `getIsProductFragrance` checks if the given `ProductTypeProps` is equal to
 * `SHOPIFY_TYPES.PRODUCT_FRAGRANCE`.
 * @param {ProductTypeProps} type - ProductTypeProps
 * @returns The function `getIsProductFragrance` returns a boolean value indicating whether the `type`
 * parameter is equal to `SHOPIFY_TYPES.PRODUCT_FRAGRANCE`.
 */
export function getIsProductFragrance(type: ProductTypeProps) {
  return type === SHOPIFY_TYPES.PRODUCT_FRAGRANCE
}

/**
 * The function `getIsProductSet` checks if the given `ProductTypeProps` is equal to
 * `SHOPIFY_TYPES.PRODUCT_SET`.
 * @param {ProductTypeProps} type - The `getIsProductSet` function takes a parameter `type` of type
 * `ProductTypeProps`. This function checks if the `type` is equal to `SHOPIFY_TYPES.PRODUCT_SET` and
 * returns a boolean value accordingly.
 * @returns The function `getIsProductSet` is returning a boolean value indicating whether the `type`
 * parameter is equal to `SHOPIFY_TYPES.PRODUCT_SET`.
 */
export function getIsProductSet(type: ProductTypeProps) {
  return type === SHOPIFY_TYPES.PRODUCT_SET
}

/**
 * The function `getIsShopifyProduct` determines if a product is related to fashion, fragrance, or a
 * set.
 * @param {ProductTypeProps} type - The `getIsShopifyProduct` function takes a `type` parameter of type
 * `ProductTypeProps`. This function checks if the product type is related to fashion, fragrance, or a
 * product set.
 * @returns The function `getIsShopifyProduct` is returning a boolean value based on the conditions of
 * `getIsProductFashion(type)`, `getIsProductFragrance(type)`, and `getIsProductSet(type)`. If any of
 * these conditions evaluate to true, the function will return true, indicating that the product is a
 * Shopify product.
 */
export function getIsShopifyProduct(type: ProductTypeProps) {
  return (
    getIsProductFashion(type) ||
    getIsProductFragrance(type) ||
    getIsProductSet(type)
  )
}
