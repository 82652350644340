import { useEffect, useRef, useState } from 'react'
import { Variant } from '~/@types/product'
import { processCartItems } from '~/lib/process-cart-items'
import { SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import { GID } from '@unlikelystudio/commerce-connector'
import { useUpdateCartAttributes } from '@unlikelystudio/react-ecommerce-hooks'

import { type ImageProps } from '~/components/UI/Image'

import { useCartActions } from '~/hooks/cart/useCartActions'
import useGetCart from '~/hooks/cart/useGetCart'
import useAlerts from '~/hooks/useAlerts'

import { objectAsCustomAttributes } from '~/utils/custom-attributes'

export interface GiftProductProps {
  name: string
  image: ImageProps
  defaultVariant: Variant
  shopifyId: GID
  type: string
}

export type UseSetGiftProductsProps = {
  gifts: GiftProductProps[]
}

/**
 * It updates the checkout with the gift products
 */
export default function useSetGiftProducts({ gifts }: UseSetGiftProductsProps) {
  const triggerAlert = useAlerts()

  const [isCheckoutLoaded, setIsCheckoutLoaded] = useState(false)
  // New gifts products ref to add into cart
  const productsToAdd = useRef(null)
  // Old gifts products ref contained into cart custom attributes
  const productsToRemove = useRef(null)
  // Action ref to handle the different use cases
  const actionRef = useRef(null)

  const {
    updateCartLinesAction,
    addLinesToCartAction,
    isMutatingCart,
    setIsMutatingCart,
  } = useCartActions({
    onErrorUpdateLines: () => triggerAlert(),
    onSuccessUpdateLines: () => {
      // Delete references of old products
      productsToRemove.current = null
      // Reset action and loading state
      resetMutationState()
    },
    onErrorAddLines: () => triggerAlert(),
    onSuccessAddLines: () => {
      // Delete references of new products
      productsToAdd.current = null

      // If we have both actions return the delete mutation
      if (actionRef.current === 'both') return handleMutationRemove()

      // Only reset if we have only a add product
      // Reset action and loading state
      resetMutationState()
    },
  })

  const { mutate: updateItems, isLoading: isRemovingProduct } =
    updateCartLinesAction

  // Reset action and loading state
  const resetMutationState = () => {
    setIsMutatingCart(false)
    actionRef.current = null
  }

  const { mutate: addItems, isLoading: isAddingProduct } = addLinesToCartAction

  // Handle add products mutation
  const handleMutationAdd = () => {
    const processedProducts = productsToAdd.current
      ?.map((variantId) => {
        // Find the product into the storyblok serialized data
        const gift = gifts?.find(
          (gift) => gift.defaultVariant?.id === variantId,
        )

        // Process checkout attributes
        const checkoutAttributes = processCartItems({
          merchandiseId: gift?.defaultVariant?.id,
          quantity: 1,
          attributes: {
            name: gift?.name,
            image: gift?.image?.src,
            shopifyId: gift?.shopifyId,
            pouch: 'true',
            prismicType: gift?.type,
          },
        })
        return checkoutAttributes
      })
      ?.flat(1)

    addItems(processedProducts)
  }

  // Handle remove products mutation
  const handleMutationRemove = () => {
    updateItems(productsToRemove.current)
  }

  // Update checkout attributes (array of variants gifts ids)
  const { mutate: updateCartCustomAttribute, isLoading } =
    useUpdateCartAttributes({
      onSuccess: () => {
        const hasProductToAdd = productsToAdd?.current?.length > 0
        const hasProductToRemove = productsToRemove?.current?.length > 0

        if (hasProductToAdd && hasProductToRemove) {
          actionRef.current = 'both'
          return handleMutationAdd()
        }
        if (hasProductToAdd) {
          actionRef.current = 'add'
          return handleMutationAdd()
        }
        if (hasProductToRemove) {
          actionRef.current = 'remove'
          return handleMutationRemove()
        }
      },
    })

  const { cart } = useGetCart()

  useEffect(() => {
    if (!cart.checkoutUrl) return
    setIsCheckoutLoaded(true)
  }, [cart])

  useEffect(() => {
    if (
      isLoading ||
      isAddingProduct ||
      isRemovingProduct ||
      isMutatingCart ||
      !isCheckoutLoaded
    )
      return

    const { attributes } = cart ?? {}
    const formattedAttributes = objectAsCustomAttributes(attributes)

    const processedVariantIds =
      gifts?.map((gift) => gift.defaultVariant?.id) ?? null

    const stringifiedVariants = processedVariantIds
      ? JSON.stringify(processedVariantIds)
      : null

    const key = SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.giftsIds
    const checkoutGiftIds = attributes?.[key] ?? null

    if (checkoutGiftIds !== stringifiedVariants) {
      // Set global loading
      setIsMutatingCart(true)

      // Add gift product to reference
      if (productsToAdd.current === null) {
        const checkoutVariantIds = cart?.products?.map(
          (product) => product?.variantId,
        )

        productsToAdd.current =
          processedVariantIds?.filter(
            (variantId) => checkoutVariantIds?.indexOf(variantId) === -1,
          ) ?? null
      }

      // Remove gift product to reference
      if (checkoutGiftIds) {
        const oldProducts = JSON.parse(checkoutGiftIds) ?? []
        const filteredProducts = oldProducts?.filter(
          (id) => processedVariantIds?.indexOf(id) === -1,
        )
        productsToRemove.current = filteredProducts?.reduce(
          (acc, variantId) => {
            return (acc = [
              ...acc,
              ...(cart?.products?.length > 0
                ? cart.products
                    .filter((item) => item.variantId === variantId)
                    .map((item) => ({ id: item.id, quantity: 0 }))
                : []),
            ])
          },
          [],
        )
      }

      // Update custom attributes into checkout
      if (stringifiedVariants) {
        updateCartCustomAttribute({
          cartId: cart.id,
          attributes: [
            ...(formattedAttributes?.length > 0
              ? formattedAttributes.filter((item) => item.key !== key)
              : []),
            ...(stringifiedVariants
              ? [
                  {
                    key,
                    value: stringifiedVariants,
                  },
                ]
              : []),
          ],
        })
      }
    }
  }, [
    isLoading,
    isAddingProduct,
    isRemovingProduct,
    isMutatingCart,
    isCheckoutLoaded,
  ])
}
