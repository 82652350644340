export function facetIdToFacetName(facet: any) {
  const [name] = facet.id.split('.').reverse()
  return name
}

export function facetNameToFacetId(
  facetName: string,
  type: 'metafield' | 'field' | null = 'metafield',
  entityType: 'product' | 'variant' | 'option' = 'product',
) {
  const facetId = `filter${type ? '.p' : ''}.${
    type === 'metafield' ? 'm' : 'v'
  }.${entityType}.${facetName}`

  return facetId
}
