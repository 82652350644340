import useGetCart from '~/hooks/cart/useGetCart'

export default function useGetCountProducts() {
  const { cart } = useGetCart()

  return cart?.products?.length
    ? cart?.products?.reduce((acc, curr) => {
        return (acc += curr.quantity)
      }, 0)
    : null
}
