import { ALL_PRODUCT_METAFIELD_KEYS } from '~/lib/shopify/metafields'

import {
  sfCollection,
  ShopifyProductCollectionSortKeys,
} from '@unlikelystudio/commerce-connector'

export const SHOPIFY_TYPES = {
  PRODUCT_FASHION: 'product_page_fashion',
  PRODUCT_FRAGRANCE: 'product_page_fragrance',
  PRODUCT_SET: 'product_page_set',
} as const

export const SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES = {
  checkoutLang: 'checkout_lang',
  params: 'params',
  giftsIds: 'gifts_ids',
  packagingFragrance: 'packaging_fragrance',
  packagingFashion: 'packaging_fashion',
  orderType: 'order_type',
  productTrackingData: '_product_tracking_data',
}

export const SHOPIFY_CUSTOM_ATTRIBUTES = {
  name: '_name',
  coupon: 'coupon',
  setsName: '_setsName',
  label: '_label',
  slug: '_slug',
  image: '_image',
  setsImage: '_setsImage',
  productType: '_productType',
  shopifyId: '_shopifyId',
  category: '_category',
  variant: '_variant',
  productVariantId: '_productVariantId',
  unserializedPrice: '_unserializedPrice',
  realProduct: '_realProduct',
  pouch: '_pouch',
  gift: '_gift',
  giftPrice: '_giftPrice',
  sample: '_sample',
  sampleFromProduct: '_sampleFromProduct',
  productLinkedId: '_productLinkedId',
  prismicType: '_prismicType',
  trackingData: '_trackingData',
  sets: '_sets',
  setsData: '_setsData',
  customPrice: '_customPrice',
  trackingPayload: '_trackingPayload',
  _color: '_color',
  _colorHex: '_colorHex',
}

// Search filters
export const SHOPIFY_SEARCH_COMMON_FILTER = {
  productMetafield: {
    key: ALL_PRODUCT_METAFIELD_KEYS.HIDE_SEARCH_RECOMMENDATION,
    namespace: 'product',
    value: 'false',
  },
}

export const SHOPIFY_SEARCH_FASHION_FILTER = {
  productMetafield: {
    key: ALL_PRODUCT_METAFIELD_KEYS.PAGE_TYPE,
    namespace: 'product',
    value: SHOPIFY_TYPES.PRODUCT_FASHION,
  },
}

export const SHOPIFY_SEARCH_FRAGRANCE_FILTER = {
  productMetafield: {
    key: ALL_PRODUCT_METAFIELD_KEYS.PAGE_TYPE,
    namespace: 'product',
    value: SHOPIFY_TYPES.PRODUCT_FRAGRANCE,
  },
}

export const SHOPIFY_SEARCH_SET_FILTER = {
  productMetafield: {
    key: ALL_PRODUCT_METAFIELD_KEYS.PAGE_TYPE,
    namespace: 'product',
    value: SHOPIFY_TYPES.PRODUCT_SET,
  },
}

export const SHOPIFY_ALL_PRODUCTS_FILTER = [
  SHOPIFY_SEARCH_COMMON_FILTER,
  SHOPIFY_SEARCH_FASHION_FILTER,
  SHOPIFY_SEARCH_FRAGRANCE_FILTER,
]

// Filters
export const SHOPIFY_FILTERS_SORT_BY_KEYS = [
  'default',
  'price_asc',
  'price_desc',
  'best_selling',
] as const

export type SHOPIFY_FILTERS_SORT_KEYS =
  (typeof SHOPIFY_FILTERS_SORT_BY_KEYS)[number]

export const SHOPIFY_FILTERS_SORT_BY: Record<
  SHOPIFY_FILTERS_SORT_KEYS,
  Pick<Parameters<typeof sfCollection>[1], 'productsSortKey' | 'productReverse'>
> = {
  default: {
    productsSortKey: ShopifyProductCollectionSortKeys.CollectionDefault,
    productReverse: false,
  },
  price_asc: {
    productsSortKey: ShopifyProductCollectionSortKeys.Price,
    productReverse: false,
  },
  price_desc: {
    productsSortKey: ShopifyProductCollectionSortKeys.Price,
    productReverse: true,
  },
  best_selling: {
    productsSortKey: ShopifyProductCollectionSortKeys.BestSelling,
    productReverse: false,
  },
} as const

export type ShopifyFiltersState = Record<string, string[]>

export const PLP_HITS_PER_PAGE = 8
