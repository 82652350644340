import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { PanelCartProps } from '~/@types/cart'
import { TRACKING_EVENTS } from '~/lib/constants'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import PanelWrapper from '~/components/Panels/PanelWrapper'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useDeletePackagings from '~/hooks/cart/useDeletePackagings'
import useGetCart, { Cart } from '~/hooks/cart/useGetCart'

const Panel = dynamic(() => import('~/components/Panels/CartPanel'))

export function findPreviousLineItem(previousCheckout, id) {
  if (!previousCheckout || !id) return null

  const checkoutItem = previousCheckout?.lines?.find(
    (edge) => edge?.node?.id === id,
  )

  return checkoutItem
}

export function fromLineItemToTrackingData(line: Cart['lines'][0]) {
  try {
    const trackingAttribute = line?.attributes?.find(
      (attr) => attr?.key === SHOPIFY_CUSTOM_ATTRIBUTES.trackingData,
    )

    if (!trackingAttribute?.value) return null

    const trackingData = JSON.parse(trackingAttribute.value)

    return {
      ...trackingData,
      quantity: line?.quantity,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export default function CartPanel(props: PanelCartProps) {
  const { cart } = useGetCart()
  const { tracker } = useTracker()
  const { global } = useGlobalData()
  const [isPanelDynamicallyLoaded, setIsPanelDynamicallyLoaded] =
    useState<boolean>(false)

  useEffect(() => {
    tracker.emit(TRACKING_EVENTS.OPEN_CART)
  }, [])

  // This useEffect is here to set when to display the panel dynamically, and its when the checkout is loaded.
  useEffect(() => {
    if (cart && !isPanelDynamicallyLoaded) {
      setIsPanelDynamicallyLoaded(true)
    }
  }, [cart, isPanelDynamicallyLoaded])

  /* It's a hook that removes the packaging from the checkout. */
  useDeletePackagings(cart)

  return (
    <PanelWrapper>
      {isPanelDynamicallyLoaded ? (
        <Panel cart={cart} data={global?.panels?.cart} {...props} />
      ) : null}
    </PanelWrapper>
  )
}
