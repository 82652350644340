import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringLiteral,
  useQueryState,
  useQueryStates,
} from 'nuqs'
import {
  createContext,
  type Dispatch,
  PropsWithChildren,
  type SetStateAction,
  useContext,
} from 'react'
import { Nullish } from '~/@types/generic'
import { objectEntries } from '~/lib/object-entries'
import { facetIdToFacetName } from '~/lib/shopify/collections/helpers'
import { ShopifyProductFilters } from '~/lib/shopify/collections/types'
import {
  SHOPIFY_FILTERS_SORT_BY_KEYS,
  SHOPIFY_FILTERS_SORT_KEYS,
} from '~/lib/shopify/constants'

import { UnlikelyCollection } from '@unlikelystudio/commerce-connector'

import { SORT_BY_PARAM } from '~/components/UI/Filters/Panels/SortBy'

import { CategoryPageData } from '~/data/category-page-data/serializer'

type SearchAndDiscoveryContextState = {
  defaultFilters: ShopifyProductFilters
  facets: (UnlikelyCollection['filters'][number] & {
    processedName: string
  })[]
  sortBy: SHOPIFY_FILTERS_SORT_KEYS
  setSortBy: Dispatch<SetStateAction<SHOPIFY_FILTERS_SORT_KEYS>>
  filters: Record<string, string[]>
  setFilters: Dispatch<SetStateAction<Record<string, string[]>>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  query: Nullish<string>
  setQuery: Dispatch<SetStateAction<Nullish<string>>>
  productFilters: ShopifyProductFilters
}

const SearchAndDiscoveryContext = createContext<SearchAndDiscoveryContextState>(
  {
    defaultFilters: [],
    facets: [],
    sortBy: 'default',
    setSortBy: () => null,
    filters: {},
    setFilters: () => null,
    page: 1,
    setPage: () => null,
    query: null,
    setQuery: () => null,
    productFilters: [],
  },
)

export function useSearchAndDiscoveryContext() {
  return useContext(SearchAndDiscoveryContext)
}

export function SearchAndDiscoveryProvider({
  children,
  defaultFilters,
  facets: initialFacets,
}: PropsWithChildren<Pick<CategoryPageData, 'facets' | 'defaultFilters'>>) {
  const [sortBy, setSortBy] = useQueryState(
    SORT_BY_PARAM,
    parseAsStringLiteral(SHOPIFY_FILTERS_SORT_BY_KEYS),
  )

  const [page, setPage] = useQueryState('page', parseAsInteger)
  const [query, setQuery] = useQueryState('q', parseAsString)

  const defaultFiltersNames =
    defaultFilters
      ?.map((entry) => {
        if (entry?.productMetafield) return entry?.productMetafield?.key
        if (entry?.variantOption) return entry?.variantOption?.name
        return null
      })
      ?.filter(Boolean) ?? []

  const facets = initialFacets
    ?.filter((facet) => {
      const name = facetIdToFacetName(facet)
      return !defaultFiltersNames?.includes(name)
    })
    ?.map((facet) => ({
      ...facet,
      processedName: facetIdToFacetName(facet),
    }))

  const [filters, setFilters] = useQueryStates(
    (facets ?? [])?.reduce((acc, facet) => {
      if (!facet?.id) return acc

      const name = facetIdToFacetName(facet)

      if (
        !name ||
        !facet?.values?.length ||
        defaultFiltersNames?.includes(name)
      )
        return acc

      return {
        ...acc,
        [name]: parseAsArrayOf(parseAsString),
      }
    }, {}),
    {
      history: 'push',
      shallow: true,
    },
  )

  const productFilters =
    objectEntries(
      filters as Record<string, string[]>,
    )?.reduce<ShopifyProductFilters>((acc, entry) => {
      const [key, valuesFromURLParams] = entry

      const relevantFacet = facets?.find(
        (facet) => facet?.processedName === key,
      )
      const { id, values } = relevantFacet ?? {}

      if (!id) return acc

      return [
        ...acc,
        ...(valuesFromURLParams?.map((value) => {
          const stringifiedInput =
            values
              ?.find((entry) => entry?.label === value)
              ?.input?.toString() ?? '{}'

          return JSON.parse(stringifiedInput)
        }) ?? []),
      ]
    }, defaultFilters ?? []) ?? []

  return (
    <SearchAndDiscoveryContext.Provider
      value={{
        defaultFilters,
        facets,
        sortBy,
        setSortBy,
        filters,
        setFilters,
        page,
        setPage,
        query,
        setQuery,
        productFilters,
      }}>
      {children}
    </SearchAndDiscoveryContext.Provider>
  )
}
