import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { NEXT_LOCALE_COOKIE } from '~/lib/constants'
import linkResolver from '~/lib/link-resolver'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import { SearchData } from '@unlikelystudio/react-abstract-components'

import FixCss from '~/components/Abstracts/FixCss'
import HackImageLcp from '~/components/Abstracts/HackImageLcp'
import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import ThirdPartyScripts from '~/components/Abstracts/ThirdPartyScripts'
import Tracking from '~/components/Abstracts/Tracking'
import Page from '~/components/Page'
import BannerMarketManager from '~/components/UI/BannerMarket/BannerManager'
import BrokenPagePlaceholder from '~/components/UI/BrokenPagePlaceholder'
import NextLoadingBar from '~/components/UI/NextLoadingBar'

import AppProviders from '~/providers/AppProviders'

import useCursorLoading from '~/hooks/useCursorLoading'
import useLocale from '~/hooks/useLocale'
import { useStableVh } from '~/hooks/useStableVh'

import getAlternateUrl from '~/utils/alternate-url'
import waitBeforeTransition from '~/utils/wait-before-transition'

import '~/styles/globals.scss'

waitBeforeTransition()

function MyApp({ Component, pageProps = {} }: AppProps) {
  const { asPath, pathname, isFallback } = useRouter()
  const key = asPath.split('?')?.[0]
  const locale = useLocale()

  useCursorLoading()
  useStableVh()

  useEffect(() => {
    // Disable setting cookie when coming from redirect routes to get the last one filled
    if (pathname !== '/redirects/[uid]') {
      Cookies.set(NEXT_LOCALE_COOKIE, locale, {
        expires: 365,
      })
    }
  }, [locale])

  const hasData = Object.keys(pageProps)?.length !== 0

  return (
    <>
      <HackImageLcp />
      <SearchData
        search={getAlternateUrl(
          linkResolver({ type: PRISMIC_TYPES.SEARCH }, locale),
        )}
        url={getAlternateUrl()}
      />

      {/* Site */}
      <AppProviders pageProps={pageProps}>
        <FixCss />
        <NextLoadingBar />
        <ThirdPartyScripts />
        <Tracking />
        {/* During revalidate */}
        {isFallback ? (
          <LoadingComponent />
        ) : hasData ? (
          <>
            <Page {...pageProps} key={key}>
              <Component {...pageProps} />
            </Page>
            <BannerMarketManager />
          </>
        ) : (
          /* Issue with data */
          <BrokenPagePlaceholder />
        )}
      </AppProviders>
    </>
  )
}

export default MyApp
