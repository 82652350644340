import { useTranslate } from 'react-polyglot'
import { SHOPIFY_FILTERS_SORT_KEYS } from '~/lib/shopify/constants'

import { DotCheckboxType } from '~/components/Form/DotCheckbox'
import {
  FiltersAndSortPanel,
  FiltersPanelProps,
} from '~/components/UI/Filters/Panels'
import { CheckboxList } from '~/components/UI/Filters/Panels/CheckboxList'

import { useSearchAndDiscoveryContext } from '~/providers/SearchAndDiscoveryProvider'

import useShop from '~/hooks/useShop'

import { FILTER } from '~/data/dictionary'

export const SORT_BY_PARAM = 'sort-by'

export enum SortByValues {
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc',
  BEST_SELLING = 'best_selling',
  DEFAULT = 'default',
}

function getSortByOrderValues(isShop: boolean) {
  if (!isShop) return [SortByValues.DEFAULT]
  return [
    SortByValues.BEST_SELLING,
    SortByValues.PRICE_DESC,
    SortByValues.PRICE_ASC,
  ]
}

export function SortBy({ closePanel }: FiltersPanelProps) {
  const isShop = useShop()
  const t = useTranslate()
  const { sortBy, setSortBy, setPage } = useSearchAndDiscoveryContext()

  return (
    <FiltersAndSortPanel
      facetComponent={({ name }) => {
        return (
          <CheckboxList
            forceScrollable
            type={DotCheckboxType.RADIO}
            label={t(FILTER.SORT_BY)}
            listIndex={0}
            values={getSortByOrderValues(isShop)?.map((sortOrder) => {
              return {
                label: t(FILTER.FACET(sortOrder)),
                name,
                value: sortOrder,
              }
            })}
          />
        )
      }}
      defaultValues={{ [SORT_BY_PARAM]: sortBy }}
      facets={[{ name: SORT_BY_PARAM }]}
      onReset={() => {
        setSortBy(null)
        setPage(1)
      }}
      onSubmit={(data) => {
        setSortBy(data?.[SORT_BY_PARAM] as SHOPIFY_FILTERS_SORT_KEYS)
        setPage(1)
        closePanel()
      }}
    />
  )
}
