import {
  MetafieldTypes,
  UnlikelyMetafield,
} from '@unlikelystudio/commerce-connector'

import { pickSubsetFromObject } from '~/utils/pick-subset-from-object'

export type ObjectValues<T> = T[keyof T]

export type MetafieldInput = {
  processedKey: string
} & UnlikelyMetafield

export enum CUSTOMER_METAFIELD_KEYS {
  BIRTHDAY = 'birthday',
  GENDER = 'gender',
}

export const PRODUCT_FILTERS_METAFIELD_KEYS = {
  MATERIAL: '_filter_material',
  SHAPE: '_filter_shape_sunglasses',
  SHADES: '_filter_shades_sunglasses',
  CUT: '_filter_cut',
  COLLAR: '_filter_collar',
  SLEEVES: '_filter_sleeves',
  LENGTH: '_filter_length',
  COLOR: '_filter_color',
  STYLE: '_filters_style',
} as const

export const ALL_PRODUCT_METAFIELD_KEYS = {
  SUBTITLE: 'subtitle',
  ACCORDIONS: 'accordions',
  ACCORDION_EXPEDITION_RETOURS: 'accordion_expedition_retours',
  ACCORDION_COMPOSITION_DETAILS: 'accordion_composition_details',
  ACCORDION_CONSEILS: 'accordion_conseils',
  ACCORDION_COMPOSITION: 'accordion_composition',
  CARD_IMAGE: 'card_image',
  IS_PRIVATE_PAGE: 'is_private_page',
  IS_GIFT_CARD: 'is_gift_card',
  COLOR_NAME: 'color_name',
  COLOR_HEXADECIMAL: 'color_hexadecimal',
  COLOR_HAS_BORDER: 'color_has_border',
  ASSOCIATED_PRODUCTS: 'associated_products',
  PRODUCT_TYPE: 'product_type',
  SAMPLE_NAME: 'sample_name',
  SAMPLE_PRODUCT: 'sample_product',
  SAMPLE_IMAGE: 'sample_image',
  CARD_IMAGE_HOVER: 'card_image_hover',
  CARD_HIDE_VARIANTS: 'card_hide_variants',
  CARD_NAME: 'card_name',
  CARD_LABEL: 'card_label',
  CARD_PIN: 'card_pin',
  SIZE_INFORMATION: 'size_information',
  INTENSITY: 'intensity',
  PAGE_TYPE: 'page_type',
  HIDE_SEARCH_RECOMMENDATION: 'hide_search_recommendation',
  BUNDLE_DISCOUNT_VALUE: 'bundle_discount_value',
  COLOR_FASHION: 'color_fashion',
  ONLINE_DTC: '_product_online_dtc',
} as const

export const PRODUCT_METAFIELD_KEYS = {
  ...pickSubsetFromObject(
    ALL_PRODUCT_METAFIELD_KEYS,
    'SUBTITLE',
    'ACCORDIONS',
    'ACCORDION_EXPEDITION_RETOURS',
    'ACCORDION_COMPOSITION_DETAILS',
    'ACCORDION_CONSEILS',
    'ACCORDION_COMPOSITION',
    'CARD_IMAGE',
    'IS_PRIVATE_PAGE',
    'IS_GIFT_CARD',
    'COLOR_HEXADECIMAL',
    'COLOR_HAS_BORDER',
    'COLOR_NAME',
    'ASSOCIATED_PRODUCTS',
    'PRODUCT_TYPE',
    'SAMPLE_NAME',
    'SAMPLE_PRODUCT',
    'SAMPLE_IMAGE',
    'PAGE_TYPE',
    'SIZE_INFORMATION',
    'BUNDLE_DISCOUNT_VALUE',
  ),
} as const

export const PRODUCT_CARD_METAFIELD_KEYS = {
  ...pickSubsetFromObject(
    ALL_PRODUCT_METAFIELD_KEYS,
    'CARD_IMAGE_HOVER',
    'CARD_HIDE_VARIANTS',
    'CARD_NAME',
    'CARD_LABEL',
    'CARD_PIN',
    'INTENSITY',
    'CARD_IMAGE',
    'COLOR_HEXADECIMAL',
    'COLOR_HAS_BORDER',
    'COLOR_NAME',
    'ASSOCIATED_PRODUCTS',
    'PRODUCT_TYPE',
    'PAGE_TYPE',
    'HIDE_SEARCH_RECOMMENDATION',
  ),
} as const

export const PRODUCT_SWAVEN_METAFIELD_KEYS = {
  ...pickSubsetFromObject(
    ALL_PRODUCT_METAFIELD_KEYS,
    'CARD_IMAGE',
    'PAGE_TYPE',
  ),
}

export type TSHOPIFY_ALL_PRODUCT_METAFIELDS_KEYS = ObjectValues<
  typeof ALL_PRODUCT_METAFIELD_KEYS
>

export type TSHOPIFY_PRODUCT_METAFIELDS_KEYS = ObjectValues<
  typeof PRODUCT_METAFIELD_KEYS
>

export type TSHOPIFY_PRODUCT_CARD_METAFIELDS_KEYS = ObjectValues<
  typeof PRODUCT_CARD_METAFIELD_KEYS
>

export const NAMESPACES = {
  CUSTOMER: 'customer',
  PRODUCT: 'product',
} as const

export type TSHOPIFY_METAFIELDS_NAMESPACES = ObjectValues<typeof NAMESPACES>

export const METAFIELD_DEFAULT_VALUE = 'unspecified'

export const METAFIELDS = {
  CUSTOMER_BIRTHDAY: {
    namespace: NAMESPACES.CUSTOMER,
    key: CUSTOMER_METAFIELD_KEYS.BIRTHDAY,
    type: MetafieldTypes.single_line_text_field,
    processedKey: `${NAMESPACES.CUSTOMER}.${CUSTOMER_METAFIELD_KEYS.BIRTHDAY}`,
  },
  CUSTOMER_GENDER: {
    namespace: NAMESPACES.CUSTOMER,
    key: CUSTOMER_METAFIELD_KEYS.GENDER,
    type: MetafieldTypes.single_line_text_field,
    processedKey: `${NAMESPACES.CUSTOMER}.${CUSTOMER_METAFIELD_KEYS.GENDER}`,
  },
  PRODUCT_SUBTITLE: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.SUBTITLE,
    type: MetafieldTypes.single_line_text_field,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.SUBTITLE}`,
  },
  PRODUCT_ACCORDIONS: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.ACCORDIONS,
    type: MetafieldTypes.metaobject_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.ACCORDIONS}`,
  },
  PRODUCT_COLOR_NAME: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.COLOR_NAME,
    type: MetafieldTypes.single_line_text_field,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.COLOR_NAME}`,
  },
  PRODUCT_COLOR_HEXADECIMAL: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.COLOR_HEXADECIMAL,
    type: MetafieldTypes.color,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.COLOR_HEXADECIMAL}`,
  },
  PRODUCT_COLOR_HAS_BORDER: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.COLOR_HAS_BORDER,
    type: MetafieldTypes.boolean,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.COLOR_HAS_BORDER}`,
  },
  PRODUCT_CARD_IMAGE: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.CARD_IMAGE,
    type: MetafieldTypes.file_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.CARD_IMAGE}`,
  },
  PRODUCT_CARD_IMAGE_HOVER: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_CARD_METAFIELD_KEYS.CARD_IMAGE_HOVER,
    type: MetafieldTypes.file_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_CARD_METAFIELD_KEYS.CARD_IMAGE_HOVER}`,
  },
  PRODUCT_ASSOCIATED_PRODUCTS: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.ASSOCIATED_PRODUCTS,
    type: MetafieldTypes.metaobject_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.ASSOCIATED_PRODUCTS}`,
  },
  PRODUCT_TYPE: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.PRODUCT_TYPE,
    type: MetafieldTypes.metaobject_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.PRODUCT_TYPE}`,
  },
  PRODUCT_IS_GIFT_CARD: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.IS_GIFT_CARD,
    type: MetafieldTypes.boolean,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.IS_GIFT_CARD}`,
  },
  PRODUCT_SAMPLE_NAME: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.SAMPLE_NAME,
    type: MetafieldTypes.single_line_text_field,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.SAMPLE_NAME}`,
  },
  PRODUCT_SAMPLE_PRODUCT: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.SAMPLE_PRODUCT,
    type: MetafieldTypes.product_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.SAMPLE_PRODUCT}`,
  },
  PRODUCT_SAMPLE_IMAGE: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.SAMPLE_IMAGE,
    type: MetafieldTypes.file_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.SAMPLE_IMAGE}`,
  },
  PRODUCT_PAGE_TYPE: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.PAGE_TYPE,
    type: MetafieldTypes.single_line_text_field,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.PAGE_TYPE}`,
  },
  PRODUCT_SIZE_INFORMATION: {
    namespace: NAMESPACES.PRODUCT,
    key: PRODUCT_METAFIELD_KEYS.SIZE_INFORMATION,
    type: MetafieldTypes.metaobject_reference,
    processedKey: `${NAMESPACES.PRODUCT}.${PRODUCT_METAFIELD_KEYS.SIZE_INFORMATION}`,
  },
} as const

export type TMetafield = typeof METAFIELDS
export type TMetafieldKey = TMetafield[keyof TMetafield]['key']
