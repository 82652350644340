import { DotCheckboxType } from '~/components/Form/DotCheckbox'
import { CheckboxList } from '~/components/UI/Filters/Panels/CheckboxList'

import { useSearchAndDiscoveryContext } from '~/providers/SearchAndDiscoveryProvider'

export function Facet({ name, index }: { name: string; index: number }) {
  const { facets } = useSearchAndDiscoveryContext()

  const facet = facets?.find((facet) => facet.processedName === name)
  const { label, values } = facet ?? {}

  if (!name) return null

  return (
    <CheckboxList
      listIndex={index}
      type={DotCheckboxType.CHECKBOX}
      label={label}
      values={values?.map((entry) => ({
        name,
        label: entry?.label,
        value: entry?.label,
      }))}
    />
  )
}
