import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTimeout } from 'react-use'
import { MARKET_BANNER_TIMEOUT } from '~/lib/constants'

import BannerMarket from '~/components/UI/BannerMarket/BannerComponent'

import { useCssVariables } from '~/providers/CssVariablesProvider'
import { useMarketContext } from '~/providers/MarketProvider'

import useMarketCookie from '~/hooks/useMarketCookie'

import css from './styles.module.scss'

export interface BannerMarketManagerProps {
  className?: string
}

export const BANNER_MARKET_CSS_VARIABLE_NAME = 'banner-offset'

function BannerMarketManager({ className }: BannerMarketManagerProps) {
  const { setDisplayMarketBanner, displayMarketBanner } = useMarketContext()

  const { setMarketBannerUserPreferences, setMarketBannerDisplayed } =
    useMarketCookie()

  const { update } = useCssVariables()
  const [timeout] = useTimeout(MARKET_BANNER_TIMEOUT)
  const [inViewRef, inView] = useInView({ initialInView: true })
  const [showMarketBanner, setMarketBanner] = useState(false)

  useEffect(() => {
    if (!inView && timeout()) setMarketBanner(true)
  }, [inView, setMarketBanner, timeout()])

  if (!showMarketBanner) {
    return <div ref={inViewRef} className={css.MarketBannerHandler} />
  }

  if (displayMarketBanner) {
    return null
  }

  return (
    <div className={className}>
      <BannerMarket
        onClickClose={() => {
          setDisplayMarketBanner?.(true)
          update(BANNER_MARKET_CSS_VARIABLE_NAME, `0px`)

          // Cookie side
          setMarketBannerDisplayed?.(true)
        }}
        onGlobalChange={(data) => {
          setMarketBannerUserPreferences?.(data)
          setDisplayMarketBanner?.(true)
          update(BANNER_MARKET_CSS_VARIABLE_NAME, `0px`)

          // Cookie side
          setMarketBannerDisplayed?.(true)
        }}
      />
    </div>
  )
}

export default BannerMarketManager
