import Script from 'next/script'
import processEnvPublic from 'unlikely-env/public'
import { NEXT_PUBLIC_SWAVEN, RAKUTEN_TRACKING_ID } from '~/lib/constants'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'
import swavenTrackingCallback from '~/lib/swaven-tracking-callback'

function ThirdPartyScripts() {
  const shop = getStorePublicCredentials()
  return (
    <>
      <Script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        strategy="beforeInteractive"
        type="text/javascript"
        data-document-language="true"
        data-domain-script={`${processEnvPublic('NEXT_PUBLIC_COOKIE_PRO_ID')}`}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
            function OptanonWrapper() {}`,
        }}
      />

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtm.js?id=${processEnvPublic(
          'NEXT_PUBLIC_GTM_ID',
        )}`}
      />

      <script
        src={`https://static.cdn.prismic.io/prismic.js?new=true&repo=${processEnvPublic(
          'NEXT_PUBLIC_PRISMIC_REPO_NAME',
        )}`}
      />

      <script
        type="text/javascript"
        src={`https://tag.rmp.rakuten.com/${RAKUTEN_TRACKING_ID}.ct.js?shop=${shop?.storeUrl?.replace(
          'https://',
          '',
        )}`}
      />

      {/* Polyfill */}
      <Script
        strategy="beforeInteractive"
        src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=Math.hypot%2CObject.assign%2CObject.keys%2CArray.prototype.findIndex%2CArray.prototype.find%2CrequestAnimationFrame%2CIntersectionObserver%2CResizeObserver%2CObject.entries"
      />

      {/* Zendesk */}
      <Script
        strategy="beforeInteractive"
        src="https://premium-plus-io.github.io/zendesk-widget/clients/puig-nina.js"
      />

      {/* Swaven Store locator */}
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function (e) {
            try {
              var a = (window.swnDataLayer = window.swnDataLayer || {})
              ;(a.appId = e || a.appId),
                (a.eventBuffer = a.eventBuffer || []),
                (a.loadBuffer = a.loadBuffer || []),
                (a.push =
                  a.push ||
                  function (e) {
                    a.eventBuffer.push(e)
                  }),
                (a.load =
                  a.load ||
                  function (e) {
                    a.loadBuffer.push(e)
                  })
              var t = document.getElementsByTagName('script')[0],
                n = document.createElement('script')
              ;(n.async = !0),
                (n.src =
                  '//wtb-tag.swaven.com/scripts/' + a.appId + '/tag.min.js'),
                t.parentNode.insertBefore(n, t)
            } catch (e) {
              console.log(e)
            }
          })('${NEXT_PUBLIC_SWAVEN}');
          window.swnDataLayer.widget_callback = ${swavenTrackingCallback};
        `,
        }}
      />
    </>
  )
}

export default ThirdPartyScripts
