import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import { customAttributesAsObject } from '~/utils/custom-attributes'

interface CustomAttributesProps {
  slug?: string
  name?: string
  setsName?: string
  image?: string
  setsImage?: string
  category?: string
  shopifyId?: string
  trackingData?: string
  gift?: string
  pouch?: string
  giftPrice?: string
  productType?: string
  sample?: string
  prismicType?: string
  realProduct?: string
  sets?: string
  setsData?: string
  sampleFromProduct?: string
  productLinkedId?: string
  customPrice?: string
  trackingPayload?: string
  color?: string
  colorHex?: string
}

export interface ProcessConfiguratorCartItemsProps {
  merchandiseId: string
  quantity: number

  attributes?: CustomAttributesProps
}

export function addCustomObject(key, value) {
  return value
    ? [
        ...(Array.isArray(key)
          ? key.map((subkey) => ({ key: subkey, value }))
          : [{ key, value }]),
      ]
    : []
}

/**
 * It takes an array of products, and returns an array of tracking data from stringified custom attributes
 * @param products - The products array from the checkout object.
 * @returns An array of tracking data objects
 */
export function getTrackingFromProcessedCartItems(products) {
  return (
    products
      ?.map((product) => {
        const attributes = customAttributesAsObject(product?.attributes)
        if (attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.trackingData]) {
          return JSON.parse(attributes[SHOPIFY_CUSTOM_ATTRIBUTES.trackingData])
        } else return null
      })
      ?.filter(Boolean)
      ?.flat(2) ?? null
  )
}

export function processCartItems({
  merchandiseId,
  quantity,
  attributes,
}: ProcessConfiguratorCartItemsProps) {
  return merchandiseId
    ? [
        {
          merchandiseId,
          quantity,
          attributes: [
            ...(attributes
              ? [
                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.name,
                    attributes?.name,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.setsName,
                    attributes?.setsName,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.slug,
                    attributes?.slug,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.image,
                    attributes?.image,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.setsImage,
                    attributes?.setsImage,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.productType,
                    attributes?.productType,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.shopifyId,
                    attributes?.shopifyId,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.trackingData,
                    attributes?.trackingData ?? null,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.gift,
                    attributes?.gift ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.pouch,
                    attributes?.pouch ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.giftPrice,
                    attributes?.giftPrice,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.sample,
                    attributes?.sample ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.sampleFromProduct,
                    attributes?.sampleFromProduct ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.sets,
                    attributes?.sets ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.setsData,
                    attributes?.setsData ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.productLinkedId,
                    attributes?.productLinkedId,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.realProduct,
                    attributes?.realProduct ?? 'false',
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.prismicType,
                    attributes?.prismicType,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES.customPrice,
                    attributes?.customPrice,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES._color,
                    attributes?.color,
                  ),

                  ...addCustomObject(
                    SHOPIFY_CUSTOM_ATTRIBUTES._colorHex,
                    attributes?.colorHex,
                  ),
                ]
              : []),
          ],
        },
      ]
    : []
}
