import { createUrl, PathRegExp } from '@marvinh/path-to-regexp'
import { SHOPIFY_TYPES } from '~/lib/shopify/constants'

import { isDefaultLocale } from '~/utils/locales'

import { PRISMIC_TYPES } from './prismic-types'

export interface LinkResolverParams {
  type: string
  uid?: string
  lang?: string
}

function getTranslatedPath(
  locale: string,
  type: string,
  uid: string,
  defaultSource: string,
) {
  const pathRegExp = new PathRegExp(defaultSource)

  return `/${locale}/${createUrl(pathRegExp, { uid })}`
}

export default function linkResolver(
  doc: LinkResolverParams,
  locale?: string,
  forceLocale?: boolean,
): string {
  const defaultLocale = isDefaultLocale(locale)

  switch (doc?.type) {
    case PRISMIC_TYPES.STORE_LOCATOR:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'store-locator')
    case PRISMIC_TYPES.CONTACT:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'contact')
    case PRISMIC_TYPES.BLOG:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'articles')
    case PRISMIC_TYPES.ARTICLE:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'articles/:uid')
    case PRISMIC_TYPES.FAQ:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'faq')
    case PRISMIC_TYPES.FAQ_ITEM:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'faq/:uid')
    case PRISMIC_TYPES.LEGAL_ITEM:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'legal/:uid')
    case PRISMIC_TYPES.LIVESTREAM:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'livestream/:uid')
    case PRISMIC_TYPES.LOOKBOOK:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'lookbook/:uid')
    case SHOPIFY_TYPES.PRODUCT_FASHION:
      return getTranslatedPath(
        locale,
        doc?.type,
        doc?.uid,
        'fashion/product/:uid',
      )
    case SHOPIFY_TYPES.PRODUCT_FRAGRANCE:
      return getTranslatedPath(
        locale,
        doc?.type,
        doc?.uid,
        'fragrance/product/:uid',
      )
    case SHOPIFY_TYPES.PRODUCT_SET:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'sets/:uid')
    case PRISMIC_TYPES.ROOT_CATEGORY_FASHION_V2:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'fashion')
    case PRISMIC_TYPES.ROOT_CATEGORY_FRAGRANCE_V2:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'fragrance')
    case PRISMIC_TYPES.CATEGORY_FASHION_V2:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'fashion/:uid')
    case PRISMIC_TYPES.CATEGORY_FRAGRANCE_V2:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'fragrance/:uid')
    case PRISMIC_TYPES.LIBRARY_UI:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'ui')
    case PRISMIC_TYPES.SEARCH:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'search')
    case PRISMIC_TYPES.ACCOUNT:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'account')
    case PRISMIC_TYPES.LOGIN:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'login')
    case PRISMIC_TYPES.REGISTER:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'register')
    case PRISMIC_TYPES.FORGOT_PASSWORD:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'forgot-password')
    case PRISMIC_TYPES.RESET_PASSWORD:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'reset-password')
    case PRISMIC_TYPES.ENABLE_ACCOUNT:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'enable-account')
    case PRISMIC_TYPES.PRIVATE:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'private')
    case PRISMIC_TYPES.MAINTENANCE_PAGE:
      return getTranslatedPath(locale, doc?.type, doc?.uid, 'maintenance')
    case PRISMIC_TYPES.VENUS:
      return getTranslatedPath(
        locale,
        doc?.type,
        doc?.uid,
        'venus-de-nina-ricci',
      )
    case PRISMIC_TYPES.HOMEPAGE:
      return !forceLocale && defaultLocale ? '/' : `/${locale}`
    default:
      if (doc?.uid)
        return getTranslatedPath(locale, doc?.type, doc?.uid, ':uid')
      else return '/'
  }
}
