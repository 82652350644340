import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const BannerContext = createContext<BannerType>({})

export function useBanner() {
  return useContext(BannerContext)
}

interface BannerType {
  hide?: () => void
  reveal?: () => void
  isVisible?: boolean
}

export interface BannerProviderProps {
  children?: JSX.Element | JSX.Element[]
}

export default function BannerProvider({ children }: BannerProviderProps) {
  const [isVisible, setIsVisible] = useState(true)

  const hide = useCallback(() => {
    setIsVisible(false)
  }, [])

  const reveal = useCallback(() => {
    setIsVisible(true)
  }, [])

  const values: BannerType = useMemo(
    () => ({
      hide,
      reveal,
      isVisible,
    }),
    [isVisible, hide, reveal],
  )

  return (
    <BannerContext.Provider value={values}>{children}</BannerContext.Provider>
  )
}
