import { useEffect, useRef } from 'react'
import { CartProps } from '~/@types/cart'
import {
  hasFashionAttribute,
  hasFashionProductInCart,
  hasFragranceAttribute,
  hasFragranceProductInCart,
} from '~/lib/custom-checkout'
import { SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import { useUpdateCartAttributes } from '@unlikelystudio/react-ecommerce-hooks'

import useGetCart from '~/hooks/cart/useGetCart'
import useAlerts from '~/hooks/useAlerts'

export default function useDeletePackagings(checkout: CartProps) {
  const triggerAlert = useAlerts()
  const { cart } = useGetCart()
  const hasFashionProduct = hasFashionProductInCart(checkout)
  const hasFragranceProduct = hasFragranceProductInCart(checkout)
  const hasFashionAttr = hasFashionAttribute(checkout)
  const hasFragranceAttr = hasFragranceAttribute(checkout)

  const needToDelete = useRef({})

  const { mutate: updateCartAttribute, isLoading } = useUpdateCartAttributes({
    onError: () => {
      triggerAlert()
    },
    onSuccess: () => {
      needToDelete.current = {}
    },
  })

  useEffect(() => {
    if (isLoading) return

    /* This is checking if the cart has a fragrance product and if it does not, it will delete the
    fragrance attribute. */
    if (!hasFragranceProduct && hasFragranceAttr) {
      needToDelete.current[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.packagingFragrance
      ] = true
    } else {
      delete needToDelete.current[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.packagingFragrance
      ]
    }

    /* This is checking if the cart has a fashion product and if it does not, it will delete the
    fashion attribute. */
    if (!hasFashionProduct && hasFashionAttr) {
      needToDelete.current[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.packagingFashion
      ] = true
    } else {
      delete needToDelete.current[
        SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES.packagingFashion
      ]
    }

    const keysToDelete = Object.keys(needToDelete.current)

    if (keysToDelete?.length > 0) {
      /* Filtering out the custom attributes that need to be deleted. */
      const processedAttributes =
        Object.entries(checkout?.attributes)
          ?.filter(([key]) => {
            return keysToDelete.includes(key) ? false : key
          })
          ?.map(([key, value]) => ({
            key,
            value,
          })) ?? []

      updateCartAttribute({
        cartId: cart.id,
        attributes: processedAttributes ?? [],
      })
    }
  }, [
    hasFragranceProduct,
    hasFragranceAttr,
    hasFashionProduct,
    hasFashionAttr,
    isLoading,
    needToDelete,
  ])
}
